<template>
    <div class="d-flex flex-column">
        <iframe :src="previewUrl" frameborder="0"></iframe>
    </div>
</template>

<script>
import { APP_SERVER_HOST } from '@/servers'

export default {
    computed: {
        previewUrl () {
            return `${APP_SERVER_HOST}/#/login?redirect_to=%2Ftask%2Ftesting%2F${this.$route.params.item_id}`
        }
    }
}
</script>

<style lang="scss" scoped>
iframe {
    margin: auto;
    border: 1px solid lightgrey;
    width: 100%;
    max-width: 1000px;
    height: 96vh;
}
</style>